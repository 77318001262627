// Generated by Framer (4b118ea)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["A9YB2xSCk", "IF2E6qco2"];

const serializationHash = "framer-nGu5q"

const variantClassNames = {A9YB2xSCk: "framer-v-enge9g", IF2E6qco2: "framer-v-pr6y9m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 20, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Close: "IF2E6qco2", Hamburger: "A9YB2xSCk"}

const getProps = ({height, id, radius, width, ...props}) => { return {...props, d2WdKj7yR: radius ?? props.d2WdKj7yR, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "A9YB2xSCk"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;radius?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, d2WdKj7yR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "A9YB2xSCk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1bx94ne = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("IF2E6qco2")
})

const onTap1s4rabe = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("A9YB2xSCk")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-enge9g", className, classNames)} data-framer-name={"Hamburger"} data-highlight layoutDependency={layoutDependency} layoutId={"A9YB2xSCk"} onTap={onTap1bx94ne} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({IF2E6qco2: {"data-framer-name": "Close", onTap: onTap1s4rabe}}, baseVariant, gestureVariant)}><motion.div className={"framer-1y3t1g5"} data-framer-name={"line"} layoutDependency={layoutDependency} layoutId={"jBJIFVseq"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: radiusForCorner(d2WdKj7yR, 3), borderBottomRightRadius: radiusForCorner(d2WdKj7yR, 2), borderTopLeftRadius: radiusForCorner(d2WdKj7yR, 0), borderTopRightRadius: radiusForCorner(d2WdKj7yR, 1), rotate: 0}} variants={{IF2E6qco2: {rotate: 45}}}/><motion.div className={"framer-1sepsw4"} data-framer-name={"line"} layoutDependency={layoutDependency} layoutId={"t5HYixomh"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: radiusForCorner(d2WdKj7yR, 3), borderBottomRightRadius: radiusForCorner(d2WdKj7yR, 2), borderTopLeftRadius: radiusForCorner(d2WdKj7yR, 0), borderTopRightRadius: radiusForCorner(d2WdKj7yR, 1), rotate: 0}} variants={{IF2E6qco2: {rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nGu5q.framer-ocjym9, .framer-nGu5q .framer-ocjym9 { display: block; }", ".framer-nGu5q.framer-enge9g { cursor: pointer; height: 30px; overflow: hidden; position: relative; width: 30px; }", ".framer-nGu5q .framer-1y3t1g5 { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: visible; position: absolute; top: 10px; width: 20px; }", ".framer-nGu5q .framer-1sepsw4 { bottom: 10px; flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: visible; position: absolute; width: 20px; }", ".framer-nGu5q.framer-v-pr6y9m.framer-enge9g { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 30px); }", ".framer-nGu5q.framer-v-pr6y9m .framer-1y3t1g5 { top: calc(50.00000000000002% - 2px / 2); }", ".framer-nGu5q.framer-v-pr6y9m .framer-1sepsw4 { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IF2E6qco2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"d2WdKj7yR":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPhynHsUen: React.ComponentType<Props> = withCSS(Component, css, "framer-nGu5q") as typeof Component;
export default FramerPhynHsUen;

FramerPhynHsUen.displayName = "Elements / Menu Icon";

FramerPhynHsUen.defaultProps = {height: 30, width: 30};

addPropertyControls(FramerPhynHsUen, {variant: {options: ["A9YB2xSCk", "IF2E6qco2"], optionTitles: ["Hamburger", "Close"], title: "Variant", type: ControlType.Enum}, d2WdKj7yR: {defaultValue: 0, displayStepper: true, max: 1, min: 0, title: "Radius", type: ControlType.Number}} as any)

addFonts(FramerPhynHsUen, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})